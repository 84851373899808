.change-permissions-modal-desktop {
  max-height: 80vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
    height: 0.5rem;
    scroll-margin-right: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    width: 3px;
    height: 1rem;
    background: #037ac9;
    cursor: pointer;
  }

  &__header-info {
    display: flex;
    flex-direction: column;

    &__icon {
      width: 1.875rem;
      height: 1.875rem;
      margin: 0 0.938rem;
    }

    &__block {
      display: flex;
      height: 3.125rem;

      &:last-child {
        margin-top: 0.625rem;
      }

      &-title,
      &-username {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.1875rem;
        color: #9899a2;
        display: flex;
        align-items: center;

        &_capitalized {
          text-transform: capitalize;
        }
      }

      &-title {
        background: #f7f8fa;
        border: 1px solid #e2e5ec;
        border-radius: 5px 0px 0px 5px;
        justify-content: center;
        padding: 0.9375rem 0.625rem;

        &_withGap {
          padding-right: 2.5rem;
        }

        &_fullWidth {
          width: 100%;
          justify-content: space-between;
        }
      }

      &-username {
        background: #f4f4f4;
        border-width: 1px 1px 1px 0px;
        border-style: solid;
        border-color: #e2e5ec;
        border-radius: 0px 5px 5px 0px;
        border-left: none;
        width: 100%;
        padding: 0.9375rem;
        border: 1px solid #e2e5ec;
      }
    }

    &__input {
      width: 77%;

      input {
        background: #f4f4f4;
        border-width: 1px 1px 1px 0px;
        border-style: solid;
        border-color: #e2e5ec;
        border-radius: 0px 5px 5px 0px;

        &::placeholder {
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.1875rem;
          color: #9899a2;
        }

        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 1.563rem;

    button {
      &:last-child {
        margin-left: 0.3125rem;
      }

      max-width: 7.563rem;
      min-width: auto;
      flex: 1;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__permissions {
    display: flex;
    flex-direction: column;
    margin-top: 0.625rem;

    &__container {
      &:first-child {
        > div {
          border-top-left-radius: 0.313rem;
          border-top-right-radius: 0.313rem;
        }
      }

      &:last-child {
        > div {
          border-bottom-left-radius: 0.313rem;
          border-bottom-right-radius: 0.313rem;
        }
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #e2e5ec;
      background: #f7f8fa;
      padding: 0.625rem 1.25rem;

      &_inner {
        padding-left: 2.5rem;
        border-radius: 0.313rem 0.313rem 0px 0px;
      }

      &-switcher {
        width: 2.375rem;
        margin: 0 0.938rem 0 0.625rem;
        align-items: center;
        display: flex;

        &_noGap {
          margin-right: 0;
        }
      }

      &-container {
        display: flex;
        align-items: center;
      }

      &-title {
        color: #f2b100;
        font-size: 0.875rem;
        font-weight: 700;

        &_inner {
          color: #037ac9;
          text-transform: capitalize;
        }
      }

      &-details {
        width: 1.563rem;
        height: 1.563rem;
        cursor: pointer;
      }

      &-dropdown {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.625rem;
        cursor: pointer;

        &_inner {
          * {
            fill: #037ac9;
            cursor: auto;
          }
        }
      }
    }
  }
}
