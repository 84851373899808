.wrapped-react-input {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &_rounded {
    border-radius: 0.25rem;
  }

  &_sizable {
    &_default {
      height: 100%;
    }
    &_low {
      height: 2rem;
    }
    &_middle {
      min-width: 11.25rem;
      // height: 2.5rem;
    }
    &_high {
      height: 3rem;
    }
  }

  &__icon {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.6094rem;
    &-svg {
      width: 1rem;
      height: 1rem;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    margin-bottom: 0.625rem;
  }

  &__calendar-icon {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 0.8125rem;
    &-svg {
      min-width: 1.125rem;
      height: 1.25rem;
    }
  }

  .react-date-picker {
    width: 100%;
    padding: 0.625rem 0.8125rem;
    background: #ffffff;
    border-radius: 5px;
    &__wrapper {
      border: none;
      flex-grow: 1;
      flex-direction: row-reverse;
    }
    &__inputGroup {
      &__input,
      &__divider,
      &__leadingZero {
        font-weight: 400;
        font-size: 1rem;
        color: #909192;
        outline: none;
      }
    }
    &__button {
      padding: 0;
      outline: none;
    }
    &__clear-button {
      display: none;
    }
    &__calendar {
      z-index: 15;
    }
  }

  // .react-date-picker--open
  //   .react-date-picker__wrapper
  //   .react-date-picker__button
  //   .wrapped-react-input__calendar-icon
  //   .wrapped-react-input__calendar-icon-svg {
  //   transform: scaleY(1);
  // }
}
