.flat-page {
  &_isMobile {
    padding: 0.625rem;
  }

  &__title {
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 23px;
    color: #fff;
    text-transform: capitalize;
  }
}
