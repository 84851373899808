.auth {
  background: #15172b;
  padding: 3.75rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__language-selector {
    position: absolute;
    top: 3.75rem;
    right: 6.25rem;
  }

  &__content {
    background: #1f2343;
    border-radius: 50px;
    min-height: 43.75rem;
    min-width: 34.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4.0625rem;
  }
  &__title-logo {
    font-weight: 700;
    font-size: 1.2609rem;
    line-height: 1.5rem;
    color: #037ac9;
    align-self: center;
    margin-top: 5px;
  }

  &__title {
    display: flex;
    flex-direction: column;

    &__logo {
      width: 7.6875rem;
      align-self: center;
    }

    &__text {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #82a0c3;
      margin-top: 5px;
      margin-bottom: 3.1875rem;
      text-align: center;
    }
  }

  &__form {
    width: 100%;
    margin-bottom: 6.875rem;
  }

  &__input-block {
    padding: 0 0.625rem;
    box-sizing: border-box;
    margin-bottom: 0.9375rem;

    &__title {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #82a0c3;
      margin-bottom: 0.625rem;
    }
  }

  &__bottom {
    box-sizing: border-box;
    padding: 0 0.625rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    margin-top: 2.5rem;
  }
  &__button {
    width: 70%;
    height: 2.8rem;
    background: #0c497a;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #fff;
    cursor: pointer;

    &_disabled {
      filter: grayscale(1);
    }
  }
}
