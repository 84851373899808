.table-head {
  $root: &;

  &__main-table-head {
    border-spacing: 0px;
  }

  &__table-head {
    padding: 0.75rem 0.625rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: uppercase;
    background: #1f2343;
    color: #e8e8ea;

    #{$root}_isMobile & {
      padding: 0.25rem;
    }
  }
}
