.how-to-start {
  width: 100%;
  padding: 0 1.875rem;

  &_isMobile {
    padding: 0.625rem;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
    &__title {
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #909192;
    }

    &__arrow {
      width: 0.4375rem;
      height: 0.75rem;
    }
  }
}
