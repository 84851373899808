.input {
  box-sizing: border-box;
  display: block;
  border: none;
  width: 100%;
  min-height: 2.5rem;
  outline: none;
  padding-left: 1rem;
  color: #909192;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.1875rem;
  font-size: 0.875rem;
  background: #d9d9d9;
  border-radius: 0.3125rem;

  &::placeholder {
    color: #909192;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill {
    -webkit-text-fill-color: #909192;
    font-size: 0.875rem;
    font-weight: 700;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &_sizable {
    &_default {
      height: 100%;
    }
    &_low {
      height: 2.5rem;
    }
    &_high {
      height: 3.125rem;
      background: #15172b;
    }
    &_rounded {
      border-radius: 1.17rem;
    }
  }

  &_type {
    &_date {
      font-size: 0.75rem;
      font-family: 'Segoe UI', sans-serif;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &_number {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &_modal {
      background: #f4f4f4;
      border-width: 1px 1px 1px 0px;
      border-style: solid;
      border-color: #e2e5ec;
      border-radius: 0px 5px 5px 0px;

      &::placeholder {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: #9899a2;
      }
    }

    &_modalSecond {
      background: #15172b;
      border: 1px solid #45484b;
      border-radius: 5px;

      &::placeholder {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: #9899a2;
      }
    }

    &_lowWhite {
      background: #ffffff;
      border-radius: 5px;

      &::placeholder {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: #909192;
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__password {
    position: absolute;
    right: 0.625rem;
    cursor: pointer;

    &-svg {
      width: 1.5rem;
      height: 1.5rem;

      & * {
        stroke: #7e89a4;
      }
    }
  }

  // &_with-eye {
  //   border: 1px solid #2d3851;
  //   border-radius: 0.625rem;
  //   font-weight: 700;
  //   font-size: 0.875rem;
  //   line-height: 1rem;

  //   &::placeholder {
  //     color: #7e89a4;
  //     font-weight: 700;
  //     font-size: 0.875rem;
  //     line-height: 1rem;
  //   }
  // }

  &_color {
    &_white {
      background: #fff;
      padding-left: 2.125rem;
      color: #7e89a4;
      &::placeholder {
        color: #7e89a4;
      }
    }
  }
}
