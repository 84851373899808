.dropdown {
  position: relative;

  &__action {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-icon {
      display: flex;
      width: 0.375rem;
      height: 1.375rem;
      border-radius: 50%;
    }

    &:hover {
      border-radius: 50%;
      background: #000000;

      svg {
        circle {
          fill: #fff;
        }
      }
    }
  }
  &__items {
    position: absolute;
    top: 2.125rem;
    left: -40%;
    background: #ffffff;
    border: 1px solid #909192;
    border-radius: 5px;
    width: 100%;
    min-width: 12.5rem;
    z-index: 2;
    max-height: 15.625rem;

    &_isMobile {
      left: unset;
      right: 0;
    }
  }

  &__item {
    cursor: pointer;
    display: flex;
    padding: 0.5625rem 1rem;

    &:hover {
      background: #f5f5f5;
    }

    &-icon {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.75rem;
    }

    &-title {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #909192;
    }
  }
}
