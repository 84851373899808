.header {
  z-index: 1;
  background: #15172b;
  width: 100%;
  padding: 1.25rem 1.875rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  border-bottom: 1px solid #45484b;

  &__user {
    color: #909192;
    display: flex;
    align-items: center;
    cursor: pointer;

    &__img {
      width: 2.5rem;
      height: 2.5rem;
      margin-left: 0.625rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}
