.list-item {
  &_active {
    background: #363447;
  }
  &__parent {
    display: flex;
    padding: 0.5rem 0.9375rem;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: #363447;
    }

    &__td {
      display: flex;
      align-items: center;
    }

    &__img-icon {
      width: 1.3125rem;
      height: 1.3125rem;
      margin-right: 1.125rem;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #037ac9;
      text-transform: capitalize;
    }

    &__img-arrow {
      width: 0.4375rem;
      height: 0.75rem;

      svg {
        width: 100%;
        height: 100%;
      }
      &_opened {
        transform: rotate(90deg);
      }
    }
  }

  &__parent_level {
    &_1 {
      padding-left: 2.1875rem;
      .list-item__parent__title {
        color: #909192;
      }
    }
    &_2 {
      padding-left: 3.4375rem;
      .list-item__parent__title {
        color: #909192;
      }
    }
  }
}
