.general {
  width: 100%;

  &__tables {
    width: 100%;
    padding: 2.0625rem 1.25rem;
    background-color: #ffffff;
    border-radius: 10px;
  }

  &_isMobile {
    padding: 0.625rem;
  }
}
