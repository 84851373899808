@import 'shared/style';

.financial-reports-table {
  background: #fff;
  margin-top: 1.75rem;
  border-radius: 0 0 0.625rem 0.625rem;
  padding: 0.625rem 1.25rem;
  $root: &;

  &_isMobile {
    overflow-x: auto;
  }

  &__main-table-head {
    border-spacing: 0;
  }

  &__table-head {
    padding: 0.75rem 0.625rem;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: uppercase;
    background: #1f2343;
    color: #e8e8ea;
    border: 0.0625rem solid #909192;
  }
  &__table {
    width: 100%;
    padding: 1.2rem 0.9375rem;
    border-spacing: 0;
    border-collapse: collapse;

    &_needTable {
      border: 1px solid #1f2343;
    }
  }

  &__paginator-wrapper {
    margin-top: 1.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__extra-info {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #fff;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: $paginationWidth) {
    &__extra-info {
      margin-bottom: 0.8rem;
    }
    &__paginator-wrapper {
      flex-direction: column;
    }
  }
}
