.withdrawal {
  &__header-info {
    display: flex;
    flex-direction: column;
    margin: 1.25rem 0;

    &__block {
      display: flex;
      height: 3.125rem;

      &:last-child {
        margin-top: 0.625rem;
      }

      &-title,
      &-username,
      &-balance {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: #9899a2;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-title,
      &-username {
        background: #f7f8fa;
        border: 1px solid #e2e5ec;
        border-radius: 5px 0px 0px 5px;
        width: 23%;
      }

      &-username {
        border-radius: 0px;
        border-left: none;
      }

      &-balance {
        background: #f4f4f4;
        border-width: 1px 1px 1px 0px;
        border-style: solid;
        border-color: #e2e5ec;
        border-radius: 0px 5px 5px 0px;
        width: 54%;
      }
    }
  }

  &__amount-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
    &__title {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #000000;
      margin-bottom: 0.5rem;
    }

    &__input {
      width: 60%;

      input {
        background: #ffffff;
        border: 1px solid #e2e5ec;
        border-radius: 5px;

        &::placeholder {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.1875rem;
          color: #9899a2;
        }

        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      margin-left: 0.3125rem;
    }
  }

  &__buttons {
    display: flex;
  }
}
