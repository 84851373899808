.change-password-agent {
  &__header-info {
    display: flex;
    flex-direction: column;
    margin: 1.25rem 0;

    &__block {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.9375rem;

      &:last-child {
        margin-top: 0.625rem;
      }

      &-title {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: #9899a2;
        display: flex;
        align-items: center;
        margin-bottom: 0.625rem;
      }
    }

    &__input {
      width: 100%;
      height: 3.125rem;

      input {
        background: #f4f4f4;
        border: 1px solid #e2e5ec;
        border-radius: 5px;

        &::placeholder {
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.1875rem;
          color: #9899a2;
        }

        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      margin-left: 0.3125rem;
    }
  }

  &__buttons {
    display: flex;
  }
}
