.select {
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 5px;
  position: relative;
  user-select: none;
  color: #909192;

  &_paginator {
    .select__item {
      width: 100%;
    }
  }

  &_open {
    border-bottom: none;
  }

  &__item {
    width: 100%;
    height: 2.5rem;
    max-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 0.625rem;
    box-sizing: border-box;

    // &_current:hover {
    //   color: #000;
    // }

    // &:hover {
    //   color: #000;
    // }
  }

  &__arrow {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    right: 1rem;
    transition: transform 0.3s ease;

    & path {
      fill: #909192;
    }
  }

  &_color {
    &_grey {
      border: 1px solid #909192;
      border-radius: 5px;
      color: #909192;
      background: transparent;
    }
    &_black {
      border: 1px solid #000;
      border-radius: 5px;
      color: #000;
      background: transparent;
    }
    &_white {
      border: 1px solid #fff;
      border-radius: 5px;
      background: transparent;
    }
  }

  &__arrow-svg {
    height: 0.571rem;
    width: 0.857rem;
  }

  &_open &__arrow {
    transform: scaleY(-1);
  }

  &__items-list {
    position: absolute;
    top: 2.125rem;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background: #ffffff;
    width: 100%;
    z-index: 2;
    max-height: 21rem;
    overflow-y: scroll;
    overflow-x: hidden;

    &_color {
      &_grey {
        top: 100%;
        border: 1px solid #909192;
        background: #ffffff;
        width: 100%;
      }

      &_black {
        top: 100%;
        border: 1px solid #000;
        background: #ffffff;
        width: 100%;
      }

      &_white {
        background: white;
      }
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 0.5rem;
      scroll-margin-right: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      width: 3px;
      height: 1rem;
      background: #037ac9;
      cursor: pointer;
    }
  }
}
