.days-filter {
  display: flex;
  width: 100%;
  $root: &;

  &__item {
    border-radius: 5px;
    padding: 0.9375rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    background: #1f2343;
    color: #e0e0e0;
    margin-right: 0.3125rem;
    cursor: pointer;

    #{$root}_isMobile & {
      width: 100%;
      background: transparent;
      font-size: 0.75rem;
      text-align: center;
      border: 1px solid #45484b;
    }

    &_active {
      background: #037ac9;
      color: #ffffff;
    }

    &:hover {
      background: #037ac9;
      color: #ffffff;
    }
  }
}
