@import 'shared/style';

.paginator {
  color: #fff;
  display: flex;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
  align-items: center;

  &__pages {
    display: flex;
    height: 100%;
  }
  &__main {
    display: flex;
  }
  &__page {
    // border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5714rem 0.8571rem;
    cursor: pointer;
    color: #000;

    &__first-img,
    &__previous-img,
    &__next-img,
    &__last-img {
      width: 0.8125rem;
      height: 1.125rem;
    }

    &_active {
      background: #037ac9;
      border-radius: 100%;
      color: #fff;
      width: 1.875rem;
      height: 1.875rem;
    }
  }

  &__select {
    width: 5rem;
  }

  @media (max-width: $paginationWidth) {
    justify-content: center;
  }
}
