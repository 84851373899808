.header-dropdown {
  position: absolute;
  top: 80px;
  background: #1f2343;
  width: 18.75rem;

  &__upper {
    height: 40%;
    background: url('../../img/header-dropdown-background.png') no-repeat center;
    padding: 0.9375rem 1.5625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__balance {
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #e8e8ea;
    }
  }

  &__user {
    display: flex;
    align-items: center;

    &__img {
      width: 2.5rem;
      height: 2.5rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__username {
      margin-left: 0.625rem;
      font-weight: 700;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #e8e8ea;
    }
  }

  &__bottom {
    height: 60%;
    display: flex;
    flex-direction: column;
    padding: 0.75rem 1.25rem 0.3125rem 1.25rem;

    &__change-password-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }

    &__change-password {
      display: flex;
      align-items: center;

      &_img {
        display: flex;
        width: 1.25rem;
        height: 0.375rem;
        margin-right: 0.9375rem;
        svg {
          width: 100%;
          height: 100%;
        }
      }

      &_title {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1rem;
        color: #9899a2;
      }
    }

    &__arrow-img {
      display: flex;
      width: 0.75rem;
      height: 0.4375rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__logout-btn {
      padding: 0.5rem 1rem;
      width: fit-content;
      height: 1.875rem;
      background: #037ac9;
      border-radius: 5px;
      color: #fff;
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 0.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 1.0625rem;
    }
  }
}
