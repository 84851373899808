.filter-block-player-history-general {
  display: flex;
  flex-direction: column;
  $root: &;

  &__block {
    display: grid;
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr 1fr;

    #{$root}_isMobile & {
      margin-top: 0;
      flex-wrap: wrap;
    }
  }

  &__filter-row {
    margin-left: 0.625rem;
    margin-top: 1.25rem;

    #{$root}_isMobile & {
      margin-left: 0;
      width: 100%;
    }

    &__title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #fff;
      margin-bottom: 0.625rem;
    }
  }

  &__filter-row-checkbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3rem;
    margin-left: 0.625rem;

    #{$root}_isMobile & {
      width: 100%;
      margin-left: 0;
      margin-top: 0.625rem;
      justify-content: flex-start;
    }

    &__title {
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #fff;
      margin-left: 0.625rem;
    }
  }

  &__search-btn {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    button {
      height: 2.5rem;
    }

    #{$root}_isMobile & {
      width: 100%;
      margin-left: 0;
      margin-top: 0.625rem;
    }
  }
}
