.reports-table-providers {
  background: #fff;
  margin-top: 1.875rem;

  &_isMobile {
    padding: 0;
    overflow: auto;
  }

  &__table {
    width: 100%;
    padding: 1.2rem 0.9375rem;
    border-spacing: 0px;
    border-collapse: collapse;
    border: 1px solid #1f2343;
  }
}
