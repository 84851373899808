.tree {
  margin-left: 0.625rem;
  width: 100%;
  background: #15172b;
  border-radius: 5px;
  min-height: 25.375rem;
  border: 1px solid #45484b;
  border-radius: 5px;

  &_isMobile {
    margin-left: 0;
  }

  &__title {
    padding: 1.25rem 1.5rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
  }

  &__back-arrow {
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    transform: rotate(180deg);
  }

  &__current-agent {
    display: flex;
    padding: 0.625rem 1.25rem 2px;
    align-items: center;
    cursor: pointer;
    background: #3b3a4a;

    &:hover {
      background: #2a2a34;
    }
    &__img-user {
      width: 1.125rem;
      height: 1.25rem;
      margin-right: 0.6875rem;

      &_active {
        * {
          fill: #037ac9;
        }
      }
    }

    &__title {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #909192;
      text-transform: capitalize;

      &_active {
        color: #037ac9;
      }
    }

    &__img-arrow {
      width: 0.75rem;
      height: 1.125rem;
      margin-right: 0.75rem;
      transform: rotate(-90deg);

      * {
        fill: #909192;
      }

      &_opened {
        transform: rotate(0);
      }

      &_active {
        * {
          fill: #037ac9;
        }
      }
    }
  }

  &__wrapper {
    background: #f5f5f5;
    overflow-x: auto;
    padding: 0 0 0.625rem;
  }
}
