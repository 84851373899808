.app {
  flex-grow: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #15172b;
  color: #9899a2;
  overflow-y: auto;

  &__wrapper {
    flex-grow: 2;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template:
      'a b b' 5rem
      'a c c' auto
      'a c c' auto / 18.75rem auto auto;
    background-color: #45484b;
    grid-gap: 1px;

    &-header {
      grid-area: b;
      width: 100%;
    }
    &-sidebar {
      grid-area: a;
      width: 100%;
    }

    &-main {
      grid-area: c;
      flex-grow: 2;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      background: #15172b;
    }
    &-content {
      min-width: 0;
      flex-grow: 2;
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-columns: 4.5fr 1fr;
      padding-left: 0.625rem;
      padding-top: 1.5625rem;
      padding-right: 1.875rem;
      grid-gap: 0 0.625rem;
    }
  }

  &__user-tree {
    width: 100%;
  }
}

@import url('https://fonts.googleapis.com/css?family=Roboto:100,400,700,900');

#root,
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background: #d9d9d9;
  display: flex;
}
*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}

html {
  font-size: 16px;
}

ul,
ol,
li,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  color: inherit;
}
