.users {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__tabs {
    margin-top: 1.875rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 23%;
    padding: 0.625rem;

    border-radius: 10px 10px 0px 0px;
    background: #d9d9d9;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #909192;

    &_active {
      background: #ffffff;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #000000;
      border: none;
    }
  }
}
