.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.75rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  user-select: none;
  text-align: center;
  text-transform: capitalize;
  color: #fff;
  outline: none;
  background: #037ac9;
  cursor: pointer;

  &__search {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
  }

  &_colors {
    &_default {
      // border: 1px solid #263154;
      border-radius: 0.3125rem;
    }

    &_default1 {
      border: none;
      text-transform: none;
    }

    &_modalBlue {
      background: #037ac9;
      border-radius: 5px;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1875rem;
      text-transform: uppercase;
      color: #ffffff;
      min-width: 9.375rem;
      height: 3.125rem;
    }

    &_modalWhite {
      min-width: 9.375rem;
      height: 3.125rem;
      background: transparent;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1875rem;
      text-transform: uppercase;
      color: #037ac9;
    }

    &_hollow {
      background: #15172b;
      border: 1px solid #45484b;
      border-radius: 5px;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.1875rem;
      color: #e8e8ea;
    }
  }

  &_sizable {
    &_default {
      height: 100%;
    }

    &_low {
      height: 2.5rem;
    }

    &_high {
      height: 3.125rem;
    }

    &_sign {
      height: 2rem;
    }
  }

  &_disable {
    background: #0c497a;
    cursor: default;
  }

  &_upperCased {
    text-transform: uppercase;
  }
}
