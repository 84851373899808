.subheader {
  padding: 1.25rem 1.875rem;
  display: flex;
  align-items: center;
  background: #15172b;

  &__first-title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    color: #fff;
    padding: 0.4375rem 0.9375rem;
    border-right: 2px solid #fff;
  }

  &__second-title {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #9899a2;
    padding: 0.4375rem 0.9375rem;
  }
}
