.information {
  display: flex;
  margin: 1.25rem 0;
  flex-direction: column;
  &__info {
    &__block {
      display: flex;
      height: 3.125rem;

      &:not(:first-child) {
        margin-top: 0.625rem;
      }

      &-title,
      &-value {
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.1875rem;
        color: #9899a2;
        display: flex;
        align-items: center;
      }

      &-title {
        background: #f7f8fa;
        border: 1px solid #e2e5ec;
        border-radius: 5px 0px 0px 5px;
        justify-content: center;
        padding: 0.9375rem 0.625rem;
        width: 40%;
      }

      &-value {
        background: #e0e0e0;
        border-width: 1px 1px 1px 0px;
        border-style: solid;
        border-color: #e2e5ec;
        border-radius: 0px 5px 5px 0px;
        border-left: none;
        width: 60%;
        padding: 0.9375rem;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1rem;
    button {
      margin-left: 0.3125rem;
    }
  }

  &__buttons {
    display: flex;
  }
}
