.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(83, 83, 83, 0.5);
  z-index: 100;
  animation: become 0.2s 1;
  animation-fill-mode: forwards;
  animation-direction: normal;

  &.modal_disappearing {
    animation: disappear 0.2s 1;
  }

  &__body {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 37.5rem;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(34, 60, 80, 0.2);
    z-index: 101;
    animation: become 0.2s 1;
    animation-fill-mode: forwards;
    animation-direction: normal;
    &.modal_disappearing {
      animation: disappear 0.2s 1;
    }

    &_isMobile {
      min-width: 95%;
    }
  }
  &__header {
    position: relative;
    padding: 1.125rem 1.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_isMobile {
      padding: 0.625rem 1.25rem;
    }
  }
  &__cross {
    width: 0.8125rem;
    height: 0.8125rem;
    cursor: pointer;
  }
  &__title {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.4375rem;
    color: #363447;
  }

  &__content {
    padding: 1.25rem 1.875rem;

    &_isMobile {
      padding: 1.5rem 0.625rem;
      max-height: 80vh;
      overflow-y: auto;
    }
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes become {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
