.adm-bets-table-row {
  &__tr {
    padding: 0.4375rem 1.875rem;
    &_needTable {
      border-bottom: 1px solid #15172b;
    }
  }
  &__td {
    padding: 0.4375rem 0.625rem;
    text-align: center;
    position: relative;
  }

  &__td {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #000;

    &__data {
      display: flex;
      align-items: center;
      justify-content: center;

      &_align {
        &_left {
          padding: 0 1.25rem;
        }
      }
    }
  }

  &__arrow {
    width: 0.6875rem;
    height: 0.4375rem;
    margin: 0 0.625rem;
  }
}
