.tree-row {
  &__parent {
    display: flex;
    padding: 2px 1.25rem;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: #3b3a4a;

    &__td {
      display: flex;
      align-items: center;
    }

    &__img-user {
      width: 1.125rem;
      height: 1.25rem;
      margin-right: 0.6875rem;

      &_active {
        * {
          fill: #037ac9;
        }
      }
    }

    &__title {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1rem;
      color: #fff;
      text-transform: capitalize;

      &_active {
        color: #037ac9;
      }
    }

    &__img-arrow {
      width: 0.75rem;
      height: 1.125rem;
      margin-right: 0.75rem;
      transform: rotate(-90deg);

      * {
        fill: #909192;
      }

      &_opened {
        transform: rotate(0);
      }

      &_active {
        * {
          fill: #037ac9;
        }
      }
    }
  }

  &__childrens {
    padding-left: 0.5rem;
  }
}
