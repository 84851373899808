.sidebar {
  background: #15172b;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-right: 1px solid #45484b;
  padding: 1.375rem 0 0;
  &_isMobile {
    width: 100%;
  }

  &__title-logo {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    margin-bottom: 5.1875rem;
    color: #037ac9;
  }

  &__logo {
    width: 6.0625rem;
    height: 6.0625rem;
    margin-bottom: 0.75rem;

    &_isMobile {
      width: 10.5rem;
      height: 2.447rem;
      margin: 3.1875rem 0;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 10;
    }
  }

  &__menu {
    background: #15172b;
  }
}
