.create-player {
  display: flex;
  flex-direction: column;

  &__form {
    display: flex;
    flex-direction: column;
    margin: 1.25rem 0;

    &__input-block {
      display: flex;
      margin-top: 0.625rem;

      &_icon {
        width: 5rem;
        height: 3.125rem;
        display: flex;
        justify-content: center;
        align-items: center;

        background: #f7f8fa;
        border: 1px solid #e2e5ec;
        border-radius: 5px 0px 0px 5px;
      }
    }
  }

  &__svg {
    width: 1.875rem;
    height: 1.875rem;
  }
  &__bottom {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 0.3125rem;
    }
  }
}
