.modify-agent {
  display: flex;
  flex-direction: column;

  &__form {
    display: flex;
    flex-direction: column;
    margin: 1.25rem 0;

    &__input-block {
      display: flex;
      margin-top: 0.625rem;

      &_icon {
        width: 5rem;
        height: 3.125rem;
        display: flex;
        justify-content: center;
        align-items: center;

        background: #f7f8fa;
        border: 1px solid #e2e5ec;
        border-radius: 5px 0px 0px 5px;
      }
    }

    &__switch-block {
      display: flex;
      margin-top: 0.625rem;
      justify-content: space-between;
      background: #f7f8fa;
      border: 1px solid #e2e5ec;
      border-radius: 5px;
      padding: 0.9375rem 1.25rem;

      &_text {
        color: #9899a2;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.1875rem;
      }

      &_box {
        width: 2.375rem;
        height: 1rem;
      }
    }

    &__switcher-box {
      width: 2.375rem;
      height: 1rem;
    }
  }

  &__svg {
    width: 1.875rem;
    height: 1.875rem;
  }
  &__bottom {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 0.3125rem;
    }
  }
}
