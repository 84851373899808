.users-filter-block {
  background: #15172b;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding: 1.25rem 2.8125rem 1.25rem 1.25rem;
  border: 1px solid #45484b;

  &__search {
    height: 3.125rem;
    display: flex;
    width: 40%;
    position: relative;

    input {
      border-radius: 5px 0px 0px 5px;
      background: #15172b;
      border: 1px solid #45484b;
    }

    &__icon {
      width: 1.5rem;

      &-wrapper {
        width: 5rem;
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;
        background: #037ac9;
      }
    }
  }

  &__advice {
    position: absolute;
    top: calc(100% + 0.25rem);
    font-size: 0.75rem;
  }

  &__buttons {
    display: flex;

    button {
      margin-left: 0.625rem;
      min-width: 10rem;
    }
  }
}
